import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";

import getQueryKeys from "../query-keys";

export interface ClinicianFlags {
  has_flag: boolean | null;
}

const useClinicianSuccessionFlag = <T = ClinicianFlags>(
  clinicianEmail: string,
  options?: UseQueryOptions<ClinicianFlags, Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).clinicianFlags(clinicianEmail),
    ({ signal }) =>
      api
        .get<ClinicianFlags>("/v2/clinician/members/clinician_flags", {
          params: {
            clinician_email: clinicianEmail,
          },
          headers: {
            Authorization: accessToken,
          },
          signal,
        })
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!clinicianEmail,
      ...(options ?? {}),
    }
  );
};

export default useClinicianSuccessionFlag;
